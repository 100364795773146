import React, { useEffect, useRef, useState } from 'react';
import { TenderTabs } from '../TenderTabs';
import { ISupervisor } from '../../../../model/user/ISupervisor';
import { ICommissioner } from '../../../../model/user/ICommissioner';
import { ITenderLocation } from '../../../../model/tender/ITenderLocation';
import { ITenderType } from '../../../../model/tender/ITenderType';
import { TenderService } from '../../service';
import {
  ErrorToast,
  SuccessToast,
  WarningToast,
} from '../../../../shared/toasters/toasters';
import { Tender } from '../../../../model/tender/Tender';
import { useLocation, useNavigate } from 'react-router-dom';
import { Company, iCompanyDocument } from '../../../../model/company/Company';
import {
  multipartFormData,
  requests,
} from '../../../../utils/helpers/api.services';
import {
  BASE_URL,
  DROPDOWNS_URL,
  TENDER_URL,
} from '../../../../utils/helpers/api.routes';
import { ITenderUpdate } from '../../../../model/tender/ITenderUpdate';
import { IRelevanceReason } from '../../../../model/tender/IRelevanceReason';
import { IClient } from '../../../../model/user/IClient';
import { DocumentComponent } from '../../../../shared/components/DocumentComponent';
import { useParams } from 'react-router';
import { FileUploader } from 'react-drag-drop-files';
import { ConfirmDeleteModal } from '../../../../shared/components/ConfirmDeleteModal';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'flatpickr/dist/themes/material_green.css';
//@ts-ignore
import Flatpickr from 'react-flatpickr';
import { Button, Modal } from 'react-bootstrap';
import { German } from 'flatpickr/dist/l10n/de';
import { english as English } from 'flatpickr/dist/l10n/default';
import { iUserData } from '../../../../model/user/User';

type LocationState = {
  state: {
    isEdit: boolean;
  };
};

export const currentLanguage = localStorage.getItem('language');

export function TenderDetails() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const isEdit = (location as LocationState)?.state?.isEdit;
  const [tender, setTender] = useState<Tender>();
  const [tenderUpdate, setTenderUpdate] = useState<ITenderUpdate>({
    applicationDeadline: '',
    client: null,
    tenderNumber: '',
    commissioner: null,
    dateOfTenderAwardance: null,
    description: '',
    irrelevanceDescription: undefined,
    irrelevanceReason: undefined,
    isRelevant: undefined,
    link: '',
    location: null,
    maxRevenue: 0,
    source: '',
    supervisor: null,
    tenderType: null,
    title: '',
    file: [],
    tenderCompanyType: 'consortium',
    loss: '',
    startDate: null,
    endDate: null,
    isProlongation: false,
    personalRequired: '',
    spaseRequired: '',
    technicalRequirements: '',
  });
  const supervisorSelect2 = document.querySelector(
    '#supervisor-select-2'
  ) as HTMLDivElement;
  const [companyId, setCompanyId] = useState('');
  const [company, setCompany] = useState<Company[]>();
  const [supervisor, setSupervisor] = useState<ISupervisor[] | null>();
  const [commissioner, setCommissioner] = useState<ICommissioner[]>();
  const [locations, setLocations] = useState<ITenderLocation[]>();
  const [tenderType, setTenderType] = useState<ITenderType[]>();
  const [irrelevanceReason, setIrrelevanceReason] = useState<
    IRelevanceReason[]
  >([]);
  const [client, setClient] = useState<IClient[]>([]);
  const [updateStates, setUpdateStates] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<iCompanyDocument>();
  const [keyForCreate, setKeyForCreate] = useState(0);
  const [selectedDropdown, setSelectedDropdown] = useState({
    fieldName: '',
    type: '',
  });
  const [valueToCreateInModal, setValueToCreateInModal] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [filePreview, setFilePreview] = useState([]);

  const handleClose = () => setShowAddModal(false);
  const handleShow = () => setShowAddModal(true);

  useEffect(() => {
    if (isEdit) {
      TenderService.getTender(+id!)
        .then((response) => {
          setTender(response);
          assignValue(response);
          setCompanyId(response?.supervisor?.company?.id.toString());
        })
        .catch((error) => {
          ErrorToast(error);
          if (error?.message === 'Request failed with status code 404')
            return navigate('/dashboard');
        });
    }
  }, [JSON.stringify(updateStates), id]);

  useEffect(() => {
    TenderService.getTenderDetails()
      .then((res) => {
        if (res) {
          setCommissioner(res.commissioner);
          setCompany(
            res.company?.filter((company: any) => company?.title?.length > 0)
          );
          setLocations(res.location);
          setTenderType(res.tenderType);
          setIrrelevanceReason(res.irrelevanceReason);
          setClient(res.client);
        }
      })
      .catch((error) => ErrorToast(error));
  }, [id, updateStates]);

  useEffect(() => {
    companyId &&
      TenderService.getPossibleSupervisors(companyId).then((response) => {
        setSupervisor(response);
      });
  }, [JSON.stringify(companyId)]);

  function updateTender() {
    multipartFormData(
      tenderUpdate?.file,
      `${TENDER_URL}/relevance-update/${id}`,
      JSON.stringify(tenderUpdate)
    )
      .then((response) => {
        SuccessToast(`${t('Tenders.toasts.success_updated_tender')}`);
        goToDashboard();
      })
      .catch((err) => ErrorToast(err));
  }

  function createTender() {
    let isEndDateOlder = moment(tenderUpdate?.startDate).from(
      tenderUpdate?.endDate
    );

    if (isEndDateOlder.includes('in '))
      return WarningToast(`${t('Tenders.toasts.endDateInvalid')}`);
    if (!tenderUpdate?.startDate)
      return WarningToast(`${t('Tenders.toasts.startDate_endDate_mandatory')}`);
    multipartFormData(
      tenderUpdate?.file,
      TENDER_URL,
      JSON.stringify(tenderUpdate),
      'POST'
    )
      .then((response) => {
        SuccessToast(`${t('Tenders.toasts.successfully_created_tender')}`);
        setKeyForCreate((prev) => prev + 1);
        goToDashboard();
      })
      .catch((err) => ErrorToast(err));
  }

  function handleChange(evt: any, isNumber: boolean = false) {
    const value = isNumber ? +evt.target.value : evt.target.value.trim();
    if (evt.target.name === 'isProlongation') {
      setTenderUpdate({
        ...tenderUpdate,
        [evt.target.name]: evt.target.checked,
      });
    } else {
      if (evt.target.name === 'startDate' || evt.target.name === 'endDate') {
        setTenderUpdate({
          ...tenderUpdate,
          [evt.target.name]: value !== '' ? value : null,
        });
      } else {
        setTenderUpdate((prev) => ({
          ...prev,
          [evt.target.name]: value,
        }));
      }
    }
  }

  function handleDatePicker(evtName: string, evtValue: string) {
    const value = new Date(evtValue);
    value.setHours(12);

    setTenderUpdate((prev) => ({
      ...prev,
      [evtName]: value,
    }));
  }

  function handleSelectTwo(event: any, type: string) {
    if (!+event?.value) {
      setSelectedDropdown({
        fieldName: event.param,
        type: type,
      });

      setShowAddModal(true);
      (clientRef!.current as any)?.clearValue();
      return;
    }

    if (type === 'company') {
      setCompanyId(event?.value);
      if (companyId !== '') {
        supervisorSelect2.children[2].children[0].children[0].innerHTML = '';
      }
    } else {
      setTenderUpdate((prev: any) => ({ ...prev, [type]: event?.value }));
    }
  }

  const changeDropdownModalHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValueToCreateInModal({ [event.target.name]: event.target.value });
  };

  function handleRadioButton(event: any) {
    const id = event.target.id;
    setTenderUpdate({
      ...tenderUpdate,
      isRelevant: id === 'relevant',
    });
  }

  function assignValue(tender: Tender) {
    setTenderUpdate({
      ...tenderUpdate,
      title: tender.title,
      tenderNumber: tender.tenderNumber,
      link: tender.link,
      source: tender.source,
      dateOfTenderAwardance: tender.dateOfTenderAwardance,
      applicationDeadline: tender.applicationDeadline,
      description: tender.description,
      maxRevenue: tender.maxRevenue,
      commissioner: tender?.commissioner?.id,
      supervisor: tender.supervisor.id,
      location: tender?.location?.id,
      tenderType: tender?.tenderType?.id,
      isRelevant: tender.isRelevant,
      irrelevanceDescription: tender.irrelevanceDescription,
      irrelevanceReason: tender?.irrelevanceReason?.id,
      startDate: tender?.startDate !== '' ? tender?.startDate : 'test',
      endDate: tender?.endDate !== '' ? tender?.endDate : undefined,
      isProlongation: tender?.isProlongation,
      personalRequired: tender.personalRequired,
      spaseRequired: tender.personalRequired,
      technicalRequirements: tender.technicalRequirements,
      loss: tender.loss,
    });
  }

  const goToDashboard = () => {
    if (tenderUpdate.isRelevant) {
      navigate(`/tender/companies/${id}`);
    } else {
      navigate(`/dashboard`);
    }
  };
  const fileTypes = ['JPG', 'PNG', 'GIF', 'PDF', 'DOCX', 'DOC'];

  const changeFileHandler = (files: any) => {
    setTenderUpdate((prev) => ({ ...prev, file: files }));
  };
  const deleteTenderDocument = () => {
    if (selectedDocument?.id) {
      TenderService.deleteTenderDocument(selectedDocument?.id)
        .then((res) => {
          if (res != undefined) {
            SuccessToast(
              `${t('Tenders.toasts.successfully_deleted_document')}`
            );
            setUpdateStates(!updateStates);
          }
        })
        .catch((err) => ErrorToast(err));
    }
  };

  const selectOptions = (
    arr: any,
    param: string,
    isSecond = false,
    a?: { value: string; label: string; param: string }
  ) => {
    const options = arr?.map((typ: any) => {
      return {
        value: typ.id,
        label: isSecond ? `${typ.firstName} ${typ.lastName}` : typ[param],
        param: param,
      };
    });
    return a ? options?.concat(a) : options;
  };

  const style = {
    control: (base: any, state: any) => ({
      ...base,
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0,
      },
    }),
  };

  const getCorrectURL = (URL: string) => {
    switch (URL) {
      case 'commissioner':
        return 'commissioner';
      case 'tenderType':
        return 'tender-type';
      case 'loss':
        return 'loss';
      case 'client':
        return 'client';
      case 'location':
        return 'location';
      default:
        return 'ERROR';
    }
  };

  const updateDropdownList = () => {
    handleClose();
    const correctURL = getCorrectURL(selectedDropdown?.type);

    requests
      .post(`${DROPDOWNS_URL}/${correctURL}`, valueToCreateInModal)
      .then((res) => {
        if (res) {
          setUpdateStates(!updateStates);
          SuccessToast(`Successfully created ${correctURL}!`);
        }
      })
      .catch((error) => ErrorToast(error));
  };

  const clientRef = useRef(null);
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  const currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
  const isSuperAdmin = currentUser?.roles?.some(
    (role: string) => role === 'ROLE_SUPER_ADMIN'
  );

  const isSubmitted = tender?.isSubmitted === true && !isSuperAdmin;
  const preview = Array.from(tenderUpdate?.file)?.map((document: any) => (
    <DocumentComponent
      setSelectedDocument={setSelectedDocument}
      setUpdateList={setUpdateStates}
      updateList={updateStates}
      document={document}
      modalId="deleteTenderDocument"
      preview={true}
    />
  ));

  return (
    <>
      <Modal show={showAddModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('Tenders.companies.fields.add')} {selectedDropdown?.type}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder={`${t('cruds.enter')} ${selectedDropdown?.fieldName}`}
            name={selectedDropdown?.fieldName}
            onChange={changeDropdownModalHandler}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            {t(`modals.close`)}
          </Button>
          <Button variant="primary" onClick={updateDropdownList}>
            {t(`modals.create`)}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="col-xl-12">
        <div className="card">
          <div className="card-body checkout-tab">
            <TenderTabs />

            <div className="row flex-column flex-lg-row my-3">
              {isEdit && (
                <div className="col-lg-1 my-3 my-lg-0">
                  <label className="form-label" htmlFor="project-title-input">
                    ID:
                  </label>
                  <input
                    name="title"
                    disabled
                    onInput={handleChange}
                    defaultValue={tender?.id}
                    type="number"
                    className="form-control"
                    maxLength={100}
                    id="project-title-input"
                  />
                </div>
              )}

              <div className="col-lg col-xl my-3 my-lg-0">
                <label className="form-label" htmlFor="project-title-input">
                  <span className="text-danger">*</span>
                  {t('Tenders.details.tenderName')}
                </label>
                <input
                  name="title"
                  onInput={handleChange}
                  defaultValue={tender?.title}
                  disabled={isSubmitted}
                  type="text"
                  className="form-control"
                  placeholder={t('Tenders.details.tenderName')}
                  maxLength={100}
                  id="project-title-input"
                />
              </div>
              <div className="col-lg col-xl my-3 my-lg-0">
                <label className="form-label" htmlFor="project-number-input">
                  {t('Tenders.details.tenderNumber')}
                </label>
                <input
                  name="tenderNumber"
                  onInput={handleChange}
                  disabled={isSubmitted}
                  defaultValue={
                    tender?.tenderNumber ? tender?.tenderNumber : ''
                  }
                  type="text"
                  className="form-control"
                  placeholder={t('Tenders.details.tenderNumber')}
                  maxLength={100}
                  id="project-number-input"
                />
              </div>

              <div className="col-xl-2 col-lg">
                <label htmlFor="commissionerName-field" className="form-label">
                  {t('Tenders.details.commissioner')}
                </label>
                <Select
                  placeholder={t('Tenders.details.select_commissioner')}
                  className="form-control p-0"
                  isDisabled={isSubmitted}
                  styles={style}
                  key={isEdit ? tender?.id : keyForCreate}
                  defaultValue={
                    isEdit && {
                      label: tender?.commissioner?.name,
                      value: tender?.commissioner?.id,
                    }
                  }
                  options={selectOptions(commissioner, 'name', false, {
                    value: 'Add commissioner',
                    label: `+ ${t('Tenders.companies.fields.add')} ${t(
                      'Tenders.details.commissioner'
                    )}`,
                    param: 'name',
                  })}
                  onChange={(e) => handleSelectTwo(e, 'commissioner')}
                />
              </div>
              <div className="col-lg-6 col-xl my-3 my-lg-0">
                <label htmlFor="choices-status-input" className="form-label">
                  {t('Tenders.details.location')}
                </label>
                <Select
                  placeholder={t('Tenders.details.select_location')}
                  className="form-control p-0"
                  isDisabled={isSubmitted}
                  styles={style}
                  key={isEdit ? tender?.id : keyForCreate}
                  defaultValue={
                    isEdit && {
                      label: tender?.location?.title,
                      value: tender?.location?.id,
                    }
                  }
                  options={selectOptions(locations, 'title', false, {
                    value: 'Add location',
                    label: `+ ${t('Tenders.companies.fields.add')} ${t(
                      'Tenders.details.location'
                    )}`,
                    param: 'title',
                  })}
                  onChange={(e) => handleSelectTwo(e, 'location')}
                />
              </div>
              <div className={`${isEdit ? 'col-lg-2' : 'col-xl-3 col-lg-6'}`}>
                <div className="mb-3 mb-lg-0">
                  <label htmlFor="choices-status-input" className="form-label">
                    {t('Tenders.details.type')}
                  </label>
                  <Select
                    placeholder={t('Tenders.details.select_type')}
                    className="form-control p-0"
                    isDisabled={isSubmitted}
                    styles={style}
                    key={isEdit ? tender?.id : keyForCreate}
                    defaultValue={
                      isEdit && {
                        label: tender?.tenderType?.title,
                        value: tender?.tenderType?.id,
                      }
                    }
                    options={selectOptions(tenderType, 'title', false, {
                      value: 'Add type',
                      label: `+ ${t('Tenders.companies.fields.add')} ${t(
                        'Tenders.details.type'
                      )}`,
                      param: 'title',
                    })}
                    onChange={(e) => handleSelectTwo(e, 'tenderType')}
                  />
                </div>
              </div>
            </div>
            <div className="row flex-column flex-lg-row my-4">
              <div className="col-lg-2 d-flex flex-row flex-lg-column  justify-content-between">
                <label className="form-label" htmlFor="project-title-input">
                  {t('Tenders.details.prolongation')}
                </label>
                <div
                  className="form-check form-switch form-switch-lg"
                  dir="ltr"
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitchsizelg"
                    key={tender?.id}
                    defaultChecked={tender?.isProlongation}
                    name="isProlongation"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-2">
                <label className="form-label" htmlFor="project-title-input">
                  {t('Tenders.details.los')}
                </label>
                <input
                  onInput={handleChange}
                  defaultValue={tender?.loss}
                  disabled={isSubmitted}
                  placeholder={t('Tenders.details.los')}
                  className="form-control"
                  maxLength={100}
                  id="project-title-input"
                  name="loss"
                />
              </div>
              <div className="col-lg-4">
                <label className="form-label" htmlFor="project-title-input">
                  <span className="text-danger">*</span>
                  {t('Tenders.details.start_date')}
                </label>
                <Flatpickr
                  autocomplete="off"
                  className="form-control"
                  name="startDate"
                  disabled={isSubmitted}
                  options={{
                    disableMobile: true,
                    dateFormat: 'd.m.Y',
                    allowInput: true,
                    locale: currentLanguage !== 'en' ? German : English,
                  }}
                  defaultValue={
                    tender?.startDate
                      ? moment(tender?.startDate)?.format('DD.MM.YYYY')
                      : ''
                  }
                  onChange={(e: any) => {
                    handleDatePicker('startDate', `${e[0]}`);
                  }}
                  onClose={(e: any) => {
                    handleDatePicker('startDate', `${e[0]}`);
                  }}
                />
              </div>

              <div className="col-lg-4 ">
                <label className="form-label" htmlFor="project-title-input">
                  <span className="text-danger">*</span>
                  {t('Tenders.details.end_date')}
                </label>
                <Flatpickr
                  autocomplete="off"
                  className="form-control"
                  disabled={isSubmitted}
                  name="endDate"
                  options={{
                    disableMobile: true,
                    dateFormat: 'd.m.Y',
                    locale: currentLanguage !== 'en' ? German : English,
                    allowInput: true,
                  }}
                  defaultValue={
                    tender?.endDate
                      ? moment(tender?.endDate)?.format('DD.MM.YYYY')
                      : ''
                  }
                  onChange={(e: any) => {
                    handleDatePicker('endDate', `${e[0]}`);
                  }}
                  onClose={(e: any) => {
                    handleDatePicker('endDate', `${e[0]}`);
                  }}
                />
              </div>
            </div>
            <div className="row flex-column flex-lg-row my-4">
              <div className="col-lg-4 ">
                <label className="form-label" htmlFor="project-title-input">
                  {t('Tenders.details.personal_required')}
                </label>
                <input
                  onInput={handleChange}
                  defaultValue={tender?.personalRequired}
                  type="text"
                  disabled={isSubmitted}
                  className="form-control"
                  placeholder={t('Tenders.details.personal_required')}
                  maxLength={100}
                  id="project-title-input"
                  name="personalRequired"
                />
              </div>
              <div className="col-lg-4 ">
                <label className="form-label" htmlFor="project-title-input">
                  {t('Tenders.details.space_room_required')}
                </label>
                <input
                  onInput={handleChange}
                  defaultValue={tender?.spaseRequired}
                  type="text"
                  disabled={isSubmitted}
                  className="form-control"
                  placeholder={t('Tenders.details.space_room_required')}
                  maxLength={100}
                  id="project-title-input"
                  name="spaseRequired"
                />
              </div>
              <div className="col-lg-4 ">
                <label className="form-label" htmlFor="project-title-input">
                  {t('Tenders.details.technical_required')}
                </label>
                <input
                  onInput={handleChange}
                  disabled={isSubmitted}
                  defaultValue={tender?.technicalRequirements}
                  type="text"
                  placeholder={t('Tenders.details.technical_required')}
                  className="form-control"
                  maxLength={100}
                  id="project-title-input"
                  name="technicalRequirements"
                />
              </div>
            </div>

            <div className="row ">
              <div className="col-lg-8">
                <label className="form-label">
                  {t('Tenders.details.project_description')}
                </label>
                <div>
                  <textarea
                    name="description"
                    disabled={isSubmitted}
                    onChange={handleChange}
                    placeholder={t('Tenders.details.project_description')}
                    defaultValue={tender?.description}
                    rows={8}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-lg-4">
                <div className="my-3 my-lg-0">
                  <label className="form-label" htmlFor="project-title-input">
                    {t('Tenders.details.max_revenue')}
                  </label>
                  <input
                    name="maxRevenue"
                    disabled={isSubmitted}
                    onChange={(e) => handleChange(e, true)}
                    defaultValue={tender?.maxRevenue}
                    placeholder={t('Tenders.details.max_revenue')}
                    type="number"
                    className="form-control"
                    id="project-title-input"
                  />
                </div>
                <label className="form-label" htmlFor="project-title-input">
                  <span className="text-danger">*</span>
                  {t('Tenders.details.application_deadline')}
                </label>
                <Flatpickr
                  autocomplete="off"
                  className="form-control"
                  disabled={isSubmitted}
                  options={{
                    disableMobile: true,
                    dateFormat: 'd.m.Y',
                    allowInput: true,
                    locale: currentLanguage !== 'en' ? German : English,
                  }}
                  defaultValue={
                    tender?.applicationDeadline
                      ? moment(tender?.applicationDeadline)?.format(
                          'DD.MM.YYYY'
                        )
                      : ''
                  }
                  name="applicationDeadline"
                  onChange={(e: any) => {
                    handleDatePicker('applicationDeadline', `${e[0]}`);
                  }}
                  onClose={(e: any) => {
                    handleDatePicker('applicationDeadline', `${e[0]}`);
                  }}
                />
                <div className="my-3 my-lg-0">
                  <label className="form-label" htmlFor="project-title-input">
                    {t('Tenders.details.date_of_tender_awardance')}
                  </label>
                </div>
                <Flatpickr
                  autocomplete="off"
                  className="form-control"
                  disabled={isSubmitted}
                  name="dateOfTenderAwardance"
                  options={{
                    disableMobile: true,
                    dateFormat: 'd.m.Y',
                    allowInput: true,
                    locale: currentLanguage !== 'en' ? German : English,
                  }}
                  defaultValue={
                    tender?.dateOfTenderAwardance
                      ? moment(tender?.dateOfTenderAwardance)?.format(
                          'DD.MM.YYYY'
                        )
                      : ''
                  }
                  onChange={(e: any) => {
                    handleDatePicker('dateOfTenderAwardance', `${e[0]}`);
                  }}
                  onClose={(e: any) => {
                    handleDatePicker('dateOfTenderAwardance', `${e[0]}`);
                  }}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-6">
                <label className="form-label" htmlFor="project-title-input">
                  {t('Tenders.details.source')}
                </label>
                <input
                  name="source"
                  disabled={isSubmitted}
                  onChange={handleChange}
                  defaultValue={tender?.source}
                  type="text"
                  placeholder={t('Tenders.details.source')}
                  className="form-control"
                  id="project-title-input"
                />
              </div>
              <div className="col-6">
                <label className="form-label" htmlFor="project-title-input">
                  {t('Tenders.details.link')}
                </label>
                <input
                  name="link"
                  disabled={isSubmitted}
                  onChange={handleChange}
                  defaultValue={tender?.link}
                  type="text"
                  placeholder={t('Tenders.details.link')}
                  className="form-control"
                  id="project-title-input"
                />
              </div>
            </div>
            {tender?.isRelevant === true ? (
              <h4 className="mt-3 text-success  py-3 w-fit alert alert-success border-soft-success">
                {t('Tenders.details.relevance_check_employee.relevant')}
              </h4>
            ) : (
              <div className="row my-4 mx-0 w-100">
                {tender?.title?.length && tender?.isRelevant === null ? (
                  <div className="col-lg-6 pt-2 mb-3 alert alert-warning border-soft-warning">
                    <h5>
                      {t(
                        'Tenders.details.relevance_check_employee.tender_relevance'
                      )}
                    </h5>
                    <div className=" d-flex align-items-center">
                      <div className="form-check form-radio-primary mb-3">
                        <input
                          onClick={handleRadioButton}
                          disabled={isSubmitted}
                          className="form-check-input"
                          type="radio"
                          name="isRelevant"
                          id="relevant"
                        />
                        <label
                          className="form-check-label fs-16px"
                          htmlFor="relevant"
                        >
                          {t(
                            'Tenders.details.relevance_check_employee.relevant'
                          )}
                        </label>
                      </div>
                      <div className="form-check form-radio-primary mb-3 ms-3">
                        <input
                          onClick={handleRadioButton}
                          disabled={isSubmitted}
                          name="isRelevant"
                          className="form-check-input"
                          type="radio"
                          id="irrelevant"
                        />
                        <label
                          className="form-check-label fs-16px"
                          htmlFor="irrelevant"
                        >
                          {t(
                            'Tenders.details.relevance_check_employee.irrelevant'
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {tenderUpdate.isRelevant === false && (
                  <div className="row  align-items-end px-0">
                    {isEdit && !!tender?.irrelevanceDescription?.length && (
                      <div className="col-md-4">
                        <h4 className=" text-danger mb-0 alert alert-danger border-soft-danger">
                          {t(
                            'Tenders.details.relevance_check_employee.irrelevant'
                          )}
                        </h4>
                      </div>
                    )}
                    <div className="col-md-4">
                      <label className="form-label">
                        <span className="text-danger">*</span>
                        {t('Tenders.details.relevance_check_employee.reason')}
                      </label>
                      <select
                        onChange={(event) => handleChange(event, true)}
                        name="irrelevanceReason"
                        className="form-select"
                        disabled={
                          (isEdit && !!tender?.irrelevanceReason) || isSubmitted
                        }
                        id="choices-status-input"
                      >
                        {isEdit && <option />}
                        {irrelevanceReason.map((reason) => (
                          <option
                            selected={
                              tender?.irrelevanceReason?.id === reason.id
                            }
                            value={reason.id}
                            key={reason.id}
                          >
                            {reason.reason}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        <span className="text-danger">*</span>
                        {t(
                          'Tenders.details.relevance_check_employee.irrelevant_description'
                        )}
                      </label>

                      <textarea
                        defaultValue={tender?.irrelevanceDescription}
                        placeholder={t(
                          'Tenders.details.relevance_check_employee.irrelevant_description'
                        )}
                        disabled={
                          (isEdit &&
                            !!tender?.irrelevanceDescription?.length) ||
                          isSubmitted
                        }
                        name="irrelevanceDescription"
                        onChange={handleChange}
                        rows={1}
                        className="form-control"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="row my-4">
              <h5>
                {t(
                  'Tenders.details.relevance_check_employee.relevance_check_employee'
                )}
              </h5>
              <div className="col-lg-3 pt-2">
                <div className="mb-3 mb-lg-0">
                  <label className="form-label" htmlFor="project-title-input">
                    <span className="text-danger">*</span>
                    {t('Tenders.details.relevance_check_employee.company')}
                  </label>
                  <Select
                    className="form-control p-0"
                    styles={style}
                    isDisabled={isSubmitted}
                    placeholder={t(
                      'Tenders.details.relevance_check_employee.company'
                    )}
                    key={isEdit ? tender?.id : keyForCreate}
                    defaultValue={{
                      label: tender?.supervisor?.company?.title,
                      value: tender?.supervisor?.company?.id,
                    }}
                    options={selectOptions(company, 'title')}
                    onChange={(e) => handleSelectTwo(e, 'company')}
                  />
                </div>
              </div>
              {companyId && (
                <div className="col-lg-3 pt-2">
                  <div className="mb-3 mb-lg-0">
                    <label className="form-label" htmlFor="project-title-input">
                      <span className="text-danger">*</span>
                      {t('Tenders.details.relevance_check_employee.supervisor')}
                    </label>
                    <Select
                      placeholder={t(
                        'Tenders.details.relevance_check_employee.supervisor'
                      )}
                      isDisabled={isSubmitted}
                      className="form-control p-0"
                      styles={style}
                      id="supervisor-select-2"
                      key={isEdit ? tender?.id : keyForCreate}
                      defaultValue={
                        isEdit && {
                          label: `${tender?.supervisor?.firstName} ${tender?.supervisor?.lastName}`,
                          value: tender?.supervisor?.id,
                        }
                      }
                      options={selectOptions(supervisor, 'firstName', true)}
                      onChange={(e) => handleSelectTwo(e, 'supervisor')}
                    />
                  </div>
                </div>
              )}

              <div className={`${companyId ? 'col-lg-6 ' : 'col-lg-9'} pt-2`}>
                <div>
                  <h5 className="mt-2">
                    {t('Tenders.details.relevance_check_employee.upload')}
                  </h5>
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Ipsum, saepe.
                  </p>
                  <label
                    htmlFor="file"
                    className="w-100 cursor-pointer file-uploader"
                  >
                    <FileUploader
                      handleChange={changeFileHandler}
                      name="file"
                      isDisabled={isSubmitted}
                      multiple
                      children={
                        <div className=" dropzone dz-clickable d-flex align-items-center justify-content-center">
                          <div className="dz-message needsclick">
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                            </div>
                            <h4>
                              {t(
                                'Tenders.details.relevance_check_employee.drop_files_here'
                              )}
                            </h4>
                          </div>
                        </div>
                      }
                      types={fileTypes}
                    />
                  </label>
                </div>
                <h5 className="mt-3">
                  {t('Tenders.details.relevance_check_employee.documents')}
                </h5>
                <div className="file-wrapper">
                  {preview.length ? (
                    preview
                  ) : tender?.documents!?.length > 0 ? (
                    tender?.documents?.map((document: iCompanyDocument) => (
                      <DocumentComponent
                        setSelectedDocument={setSelectedDocument}
                        setUpdateList={setUpdateStates}
                        updateList={updateStates}
                        document={document}
                        modalId="deleteTenderDocument"
                      />
                    ))
                  ) : (
                    <>
                      <p className="text-muted">
                        {t(
                          'Tenders.details.relevance_check_employee.not_uploaded'
                        )}
                        .
                      </p>
                    </>
                  )}
                  {/* {tender?.documents!?.length > 0 ? (
                    tender?.documents?.map((document: iCompanyDocument) => (
                      <DocumentComponent
                        setSelectedDocument={setSelectedDocument}
                        setUpdateList={setUpdateStates}
                        updateList={updateStates}
                        document={document}
                        modalId="deleteTenderDocument"
                      />
                    ))
                  ) : (
                    <>
                      <p className="text-muted">
                        {t(
                          'Tenders.details.relevance_check_employee.not_uploaded'
                        )}
                        .
                      </p>
                    </>
                  )} */}
                  <ConfirmDeleteModal
                    modalId="deleteTenderDocument"
                    title="Tender document"
                    deleteItem={deleteTenderDocument}
                  />
                </div>
              </div>

              {/* OVDE RADIM */}
            </div>
            <div className="row">
              <div className="col-12">
                <div className="text-end my-4">
                  <span className="text-danger">*</span>{' '}
                  {t('Tenders.details.mandatory_fields')}
                </div>
              </div>

              <div className="col-12">
                <div className="text-end ">
                  <button
                    onClick={isEdit ? updateTender : createTender}
                    className={`btn btn-${isEdit ? 'primary' : 'success'} w-sm`}
                  >
                    {t(
                      `Tenders.details.${isEdit ? 'save_continue' : 'create'}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
