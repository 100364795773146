import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import { IChecklist, ICreateTender } from '../../../../model/tender/IChecklist';
import { User } from '../../../../model/user/User';
import { TenderService } from '../../service';
import { ErrorToast, SuccessToast } from '../../../../shared/toasters/toasters';
import { useSelector } from 'react-redux';
import { IAllStates } from '../../../../model/shared/IAllStates';
import { IDocument } from '../../../../model/shared/IDocument';
import { multipartFormData } from '../../../../utils/helpers/api.services';
import {
  TENDER_CHECKLIST_URL,
  TENDER_URL,
} from '../../../../utils/helpers/api.routes';
import { iCompanyDocument } from '../../../../model/company/Company';
import { FileUploader } from 'react-drag-drop-files';
import { PreviewDocumentComponent } from '../../../../shared/components/PreviewDocumentComponent';
import { useTranslation } from 'react-i18next';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { PriortyTask, StatusTask } from '../../../../shared/enums/Enums';
import { German } from 'flatpickr/dist/l10n/de';
import { english as English } from 'flatpickr/dist/l10n/default';
import { currentLanguage } from '../details/TenderDetails';
import { Button, Modal } from 'react-bootstrap';
interface IProps {
  updateList?: boolean;
  setUpdateList?: React.Dispatch<SetStateAction<boolean>>;
  selectedTaskID?: number;
  setSelectedTaskID?: React.Dispatch<SetStateAction<number>>;
  setSelectedTaskDocumentID?: React.Dispatch<SetStateAction<number>>;
  setIsModalActive?: React.Dispatch<SetStateAction<boolean>>;
  modalId: string;
  isEditModalActive?: boolean;
  isModalActive?: boolean;
  showModalHandler?: () => void;
  hideModalHandler: () => void;
}

export function TenderCheckListModal({
  updateList,
  setUpdateList,
  selectedTaskID,
  setSelectedTaskDocumentID,
  modalId,
  isEditModalActive,
  isModalActive,

  hideModalHandler,
}: IProps) {
  const [keyForCreate, setKeyForCreate] = useState(0);

  const [createTask, setCreateTask] = useState<ICreateTender>({
    companyDocuments: [],
    dueDate: '',
    priority: '',
    status: '',
    title: '',
    user: 0,
    taskDocuments: [],
  });
  const [workers, setWorkers] = useState<User[]>([]);
  const [documents, setDocuments] = useState<IDocument[]>();
  const [task, setTask] = useState<IChecklist>();
  const { TenderTaskStatusEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const { TenderTaskPriorityEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const location = useLocation();
  const tenderId = location.pathname.split('/')[3];
  const { t } = useTranslation();

  useEffect(() => {
    selectedTaskID &&
      TenderService.getTenderTask(selectedTaskID!)
        .then((response) => {
          setTask(response);
        })
        .catch((err) => ErrorToast(err));
  }, [selectedTaskID, modalId, updateList]);

  useEffect(() => {
    TenderService.getTaskListWorkers(+tenderId, { page: 1, perPage: 10000 })
      .then((response) => {
        setWorkers(response.data);
      })
      .catch((err) => ErrorToast(err));
  }, []);

  useEffect(() => {
    TenderService.getTaskListDocuments(+tenderId, { page: 1, perPage: 10000 })
      .then((response) => {
        setDocuments(response.data);
      })
      .catch((err) => ErrorToast(err));
  }, []);

  function addTask() {
    multipartFormData(
      createTask?.taskDocuments,
      `${TENDER_CHECKLIST_URL}/${tenderId}`,
      JSON.stringify(createTask),
      'POST'
    )
      .then((response) => {
        if (response?.data) {
          setCreateTask({
            companyDocuments: [],
            dueDate: '',
            priority: '',
            status: '',
            title: '',
            user: 0,
            taskDocuments: [],
          });
          setKeyForCreate((prev) => prev + 1);
          hideModalHandler();
          SuccessToast(
            `${t('Tenders.Checklist.toasts.successfully_created_task')}`
          );
          setUpdateList!(!updateList);
        }
      })
      .catch((err) => {
        const error = JSON.parse(JSON.stringify(err));
        ErrorToast(err);
        if (error.status === 409) {
          setCreateTask({ ...createTask, taskDocuments: [] });
        }
      });
  }

  function updateTask() {
    multipartFormData(
      createTask?.taskDocuments,
      `${TENDER_CHECKLIST_URL}/${task?.id}`,
      JSON.stringify({
        ...createTask,
        user: createTask?.user ? createTask?.user : task?.user?.id,
        priority: createTask?.priority ? createTask?.priority : task?.priority,
        dueDate: createTask?.dueDate ? createTask?.dueDate : task?.dueDate,
        status: createTask?.status ? createTask?.status : task?.status,
        title: createTask?.title ? createTask?.title : task?.title,
        companyDocuments:
          createTask?.companyDocuments && createTask?.companyDocuments,
      })
    )
      .then((response) => {
        SuccessToast(
          `${t('Tenders.Checklist.toasts.successfully_updated_task')}`
        );
        hideModalHandler();
        setUpdateList!(!updateList);
      })
      .catch((err) => ErrorToast(err));

    setCreateTask({
      companyDocuments: [],
      dueDate: '',
      priority: '',
      status: '',
      title: '',
      user: 0,
      taskDocuments: [],
    });
  }

  const selectOptions = (
    arr: any,
    param: any,
    param2?: string,
    isString = false
  ) => {
    if (isString) {
      return arr?.map((typ: string) => {
        return {
          value: typ,
          label: typ,
        };
      });
    } else {
      return arr?.map((typ: any) => {
        return {
          value: typ.id,
          label: param2
            ? `${typ?.firstName} ${typ?.lastName}`
            : typ.file
            ? typ.file.nameOriginal
            : typ[param],
        };
      });
    }
  };

  const changeHandler = (event: any, type = '') => {
    const tempArr: number[] = [];
    if (type === 'companyDocuments') {
      event.forEach((document: any) => {
        tempArr.push(document?.value);
      });
    }
    if (type !== '') {
      setCreateTask((prev: any) => ({
        ...prev,
        [type]: type !== 'companyDocuments' ? event?.value : tempArr,
      }));
    } else {
      setCreateTask({
        ...createTask,
        [event.target.name]: event.target.value,
      });
    }
  };

  function handleDatePicker(evtName: string, evtValue: string) {
    const value = new Date(evtValue);
    value.setHours(12);
    setCreateTask((prev) => ({ ...prev, [evtName]: value }));
  }

  const style = {
    control: (base: any, state: any) => ({
      ...base,
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? 0 : 0,
      },
    }),
  };

  const fileTypes = ['JPG', 'PNG', 'GIF', 'PDF', 'DOCX', 'DOC'];

  const changeFileHandler = (files: any) => {
    setCreateTask((prev) => ({ ...prev, taskDocuments: files }));
  };

  const getCorrectDocuments = (allDocuments: any, selectedDocuments: any) => {
    const selectedDocumentsId = selectedDocuments?.companyDocuments.map(
      (document: { id: number }) => document?.id
    );

    return allDocuments?.filter(
      (document: { id: number }) => !selectedDocumentsId?.includes(document?.id)
    );
  };

  useEffect(() => {
    if (task?.id) {
      updateTask();
      // @ts-ignore
      setTask((prev) => ({ ...prev, id: undefined }));
    }
  }, [JSON.stringify(createTask?.taskDocuments)]);

  return (
    <>
      <Modal
        show={modalId === 'updateModal' ? isEditModalActive : isModalActive}
        size="lg"
      >
        <Modal.Header className="bg-soft-info p-3">
          <Modal.Title className="w-100 d-flex justify-content-between ">
            <h5 className="modal-title" id="exampleModalLabel">
              {t(
                modalId === 'createModal'
                  ? 'Tenders.Checklist.modal.create_modal'
                  : 'Tenders.Checklist.modal.update_modal'
              )}
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              id="close-modal"
              onClick={hideModalHandler}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="modal-body">
              <input type="hidden" id="tasksId" />
              <div className="row g-3">
                <div className="col-lg-12">
                  <div>
                    <label htmlFor="tasksTitle-field" className="form-label">
                      {t('Tenders.Checklist.modal.task_title')}
                    </label>
                    <input
                      type="text"
                      onChange={changeHandler}
                      name="title"
                      key={
                        modalId === 'createModal' ? keyForCreate : task?.title
                      }
                      defaultValue={
                        modalId === 'createModal'
                          ? createTask?.title
                          : task?.title
                      }
                      id="tasksTitle-field"
                      className="form-control"
                      placeholder="Title"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <label htmlFor="clientName-field" className="form-label">
                    {t('Tenders.Checklist.modal.user')}
                  </label>
                  {(task?.user?.firstName || modalId === 'createModal') && (
                    <Select
                      placeholder={t('Tenders.Checklist.modal.select_users')}
                      className="form-control p-0"
                      styles={style}
                      key={
                        modalId === 'createModal'
                          ? keyForCreate
                          : task?.user?.id
                      }
                      defaultInputValue={`${
                        modalId === 'createModal'
                          ? ''
                          : `${task?.user?.firstName} ${task?.user?.lastName}`
                      }`}
                      options={selectOptions(workers, 'firstName', 'lastName')}
                      onChange={(e) => changeHandler(e, 'user')}
                    />
                  )}
                </div>
                <div className="col-lg-6">
                  <label htmlFor="clientName-field" className="form-label">
                    {t('Tenders.Checklist.modal.date')}
                  </label>
                  <Flatpickr
                    className="form-control"
                    name="dueDate"
                    key={modalId === 'createModal' ? keyForCreate : task?.id}
                    options={{
                      disableMobile: true,
                      dateFormat: 'd.m.Y',
                      allowInput: true,
                      locale: currentLanguage !== 'en' ? German : English,
                    }}
                    defaultValue={
                      task?.dueDate
                        ? moment(task?.dueDate)?.format('DD.MM.YYYY')
                        : ''
                    }
                    onChange={(e: any) => {
                      handleDatePicker('dueDate', `${e[0]}`);
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="ticket-status" className="form-label">
                    {t('Tenders.Checklist.modal.status')}
                  </label>
                  <select
                    name="status"
                    className="form-control"
                    defaultValue={task?.status}
                    key={
                      modalId === 'createModal' ? keyForCreate : task?.user?.id
                    }
                    onChange={(e) => changeHandler(e)}
                  >
                    <option value={task?.status} hidden>
                      {t(
                        StatusTask[
                          task?.status as keyof typeof StatusTask
                        ] as string
                      )?.toUpperCase()}
                    </option>
                    {TenderTaskStatusEnum?.map((status, i) => {
                      return (
                        <option value={status} key={i}>
                          {t(
                            StatusTask[
                              status as keyof typeof StatusTask
                            ] as string
                          )?.toUpperCase()}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-lg-6">
                  <label htmlFor="priority-field" className="form-label">
                    {t('Tenders.Checklist.modal.priority')}
                  </label>
                  <select
                    name="priority"
                    className="form-control"
                    key={
                      modalId === 'createModal' ? keyForCreate : task?.user?.id
                    }
                    defaultValue={task?.priority}
                    onChange={(e) => changeHandler(e)}
                  >
                    <option value={task?.priority} hidden>
                      {t(
                        PriortyTask[
                          task?.priority as keyof typeof PriortyTask
                        ] as string
                      )?.toUpperCase()}
                    </option>
                    {TenderTaskPriorityEnum?.map((priority, i) => {
                      return (
                        <option value={priority} key={i}>
                          {t(
                            PriortyTask[
                              priority as keyof typeof PriortyTask
                            ] as string
                          )?.toUpperCase()}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-lg-6">
                  <h5 className="mt-2">
                    {t('Tenders.Checklist.modal.select_file')}
                  </h5>
                  <Select
                    placeholder={t('Tenders.Checklist.modal.select_files')}
                    className="form-control p-0"
                    styles={style}
                    key={modalId === 'createModal' ? keyForCreate : task?.id}
                    options={selectOptions(
                      getCorrectDocuments(documents, task),
                      'file'
                    )}
                    // isClearable={true}
                    isMulti={true}
                    onChange={(e) => changeHandler(e, 'companyDocuments')}
                  />
                  {modalId === 'updateModal' && (
                    <>
                      <h5 className="mt-3">
                        {t('Tenders.Checklist.modal.documents_title')}
                      </h5>
                      <div className="file-wrapper">
                        {task?.companyDocuments!?.length > 0 ? (
                          task?.companyDocuments?.map(
                            (document: iCompanyDocument) => (
                              <PreviewDocumentComponent
                                modalId="deleteCompanyDocument"
                                setSelectedDocument={setSelectedTaskDocumentID}
                                document={document}
                              />
                            )
                          )
                        ) : (
                          <>
                            <p className="text-muted">
                              You don't have any files uploaded yet.
                            </p>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="col-lg-6">
                  <h5 className="mt-2">
                    {t('Tenders.Checklist.modal.upload_file')}
                  </h5>
                  <label
                    htmlFor="fileInput"
                    className="w-100 cursor-pointer fileInput"
                  >
                    <FileUploader
                      handleChange={changeFileHandler}
                      name="file"
                      multiple
                      children={
                        <div className=" dropzone dz-clickable d-flex align-items-center justify-content-center">
                          <div className="dz-message needsclick">
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                            </div>
                            <h4>
                              {t('Tenders.Checklist.modal.upload_file_title')}
                            </h4>
                          </div>
                        </div>
                      }
                      types={fileTypes}
                    />
                  </label>
                  {modalId === 'updateModal' ? (
                    <>
                      <h5 className="mt-3">
                        {t('Tenders.Checklist.modal.documents_title')}
                      </h5>
                      <div className="file-wrapper">
                        {task?.taskDocuments!?.length > 0 ? (
                          task?.taskDocuments?.map(
                            (document: iCompanyDocument) => (
                              <PreviewDocumentComponent
                                modalId="deleteTaskDocument"
                                setSelectedDocument={setSelectedTaskDocumentID}
                                document={document}
                              />
                            )
                          )
                        ) : (
                          <>
                            <p className="text-muted">
                              {t('Tenders.Checklist.modal.no_documents_text')}
                            </p>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {Array.from(createTask?.taskDocuments)?.map(
                        (file: { name: string }, i: number) => {
                          return (
                            <PreviewDocumentComponent
                              key={i}
                              isButtonVisible={false}
                              setSelectedDocument={setSelectedTaskDocumentID}
                              document={file as any}
                            />
                          );
                        }
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={hideModalHandler}>
            {t('Tenders.Checklist.modal.close')}
          </Button>
          <Button
            variant="primary"
            onClick={modalId === 'updateModal' ? updateTask : addTask}
          >
            {t('Tenders.Checklist.modal.save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
